import React, { useEffect, useReducer } from "react"

// Reducers
import { AuthReducer } from './reducer'

// Services
import { getUserInfo } from '../../../services/Auth'

// Context
export const AuthStateContext = React.createContext()

export const AuthProvider = ({ children }) => {
  // const history = useHistory()
  const initialState = {
    user: { modules:[] },
    loading: false,
    errorMessage: null,
    successMessage: null,
    socketIo: null,
    notifications:[]
  }

  const [authState, dispatch] = useReducer(AuthReducer, initialState)

  useEffect(() => {
    (async function() {
      try {
        const token = window.localStorage.getItem('@TOKEN')

        if (token !== null) {
          const res = await getUserInfo()
          if (res.status === 200) {
            window.localStorage.setItem('@USER', JSON.stringify(res.result))
            dispatch({ type:'USER_INFO', payload: { user: res.result } })
          }
        }
      } catch (error) {}
    })()
  }, [])

  return (
    <AuthStateContext.Provider value={{ ...authState, dispatch }}>
      {children}
    </AuthStateContext.Provider>
  )
}
